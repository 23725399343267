import React, { useContext,useState,useEffect,Suspense, lazy } from 'react';
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import { MyContext } from '../contexts/MyContext';
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('./login/Login'));
const Register = lazy(() => import('./login/Register'));
const ForgetPassword = lazy(() => import('./login/ForgetPassword'));
const ChangePassword = lazy(() => import('./login/ChangePassword'));
const Logout = lazy(() => import('./login/Logout'));
const MyAccount = lazy(() => import('./myaccount/MyAccount'));
const MyAccountReaders = lazy(() => import('./myaccount/readers/MyAccountReaders'));
const MyAccountAuthors = lazy(() => import('./myaccount/authors/MyAccountAuthors'));
const Books = lazy(() => import('./Books'));
const AuthorPage = lazy(() => import('./AuthorPage'));
const Platinum = lazy(() => import('./Platinum'));
const Gold = lazy(() => import('./Gold'));
const Silver = lazy(() => import('./Silver'));
const Featured = lazy(() => import('./Featured'));
const Free = lazy(() => import('./Free'));
const Categeorylist = lazy(() => import('./Categeorylist'));
const Events = lazy(() => import('./Events'));
const EventsPreviews = lazy(() => import('./EventsPreviews'));
const BookTrailers = lazy(() => import('./BookTrailers'));
const Articles = lazy(() => import('./Articles'));
const Poems = lazy(() => import('./Poems'));
const VideoPreview = lazy(() => import('./VideoPreview'));
const ArticlesPreview = lazy(() => import('./ArticlesPreview'));
const Authors = lazy(() => import('./Authors'));
const Notificationall = lazy(() => import('./Notificationall'));
const ConfirmEmail = lazy(() => import('./ConfirmEmail'));
const FeaturedBookcolors = lazy(() => import('./FeaturedBookcolors'));
const FeaturedPaymentStepOne = lazy(() => import('./FeaturedPaymentStepOne'));
const FeaturedPaymentSteptwo = lazy(() => import('./FeaturedPaymentSteptwo'));
const PaypalSuccess = lazy(() => import('./PaypalSuccess'));
const PaypalCancel = lazy(() => import('./PaypalCancel'));
const StripeSuccess = lazy(() => import('./StripeSuccess'));
const CategeorylistRecommended = lazy(() => import('./CategeorylistRecommended'));
const CategeorylistLatest = lazy(() => import('./CategeorylistLatest'));
const PoemsPreview = lazy(() => import('./PoemsPreview'));
const AdminFreeBooks = lazy(() => import('./admin/AdminFreeBooks'));
const AdminPaidBooks = lazy(() => import('./admin/AdminPaidBooks'));
const ContactBookois = lazy(() => import('./ContactBookois'));
const CategeorylistBestsellers = lazy(() => import('./CategeorylistBestsellers'));
const AdminVideo = lazy(() => import('./admin/AdminVideo'));
const AdminArticle = lazy(() => import('./admin/AdminArticle'));
const AdminPoem = lazy(() => import('./admin/AdminPoem'));
const EventsOldest = lazy(() => import('./EventsOldest'));
const EventsNewest = lazy(() => import('./EventsNewest'));
const EventsRegisterdlist = lazy(() => import('./EventsRegisterdlist'));
const EventsViewList = lazy(() => import('./EventsViewList'));
const AdminEvent = lazy(() => import('./admin/AdminEvent'));
const AdminReport = lazy(() => import('./admin/AdminReport'));
const AdminPressRelease = lazy(() => import('./admin/AdminPressRelease'));
const AdminPressReleasePreview = lazy(() => import('./admin/AdminPressReleasePreview'));
const AdminPressReleaseEdit = lazy(() => import('./admin/AdminPressReleaseEdit'));
const PressRelease = lazy(() => import('./PressRelease'));
const PressReleaseEdit = lazy(() => import('./PressReleaseEdit'));
const Pressauthorpreviews = lazy(() => import('./Pressauthorpreviews'));
const PressReleaseall = lazy(() => import('./PressReleaseall'));
const PressReleasePreview = lazy(() => import('./PressReleasePreview'));
const AuthorUpdates = lazy(() => import('./AuthorUpdates'));
const AuthorUpdatespreviews = lazy(() => import('./AuthorUpdatespreviews'));
const AuthorsupdateEdit = lazy(() => import('./AuthorsupdateEdit'));
const AuthorUpdatesall = lazy(() => import('./AuthorUpdatesall'));
const AuthorUpdatesallPreviews = lazy(() => import('./AuthorUpdatesallPreviews'));
const SearchAllBooks = lazy(() => import('./SearchAllBooks'));
const SearchAllAuthors = lazy(() => import('./SearchAllAuthors'));
const SearchAllEvents = lazy(() => import('./SearchAllEvents'));
const SearchAllArticles = lazy(() => import('./SearchAllArticles'));
const SearchAllPoems = lazy(() => import('./SearchAllPoems'));
const SearchAllVideos = lazy(() => import('./SearchAllVideos'));
const SearchAllPressRelease = lazy(() => import('./SearchAllPressRelease'));
const AdminReview = lazy(() => import('./admin/AdminReview'));
const FbStatus = lazy(() => import('./login/FbStatus'));
const About = lazy(() => import('./About'));





function Dashboard() {
  const {rootState,isLoggedInemailempty} = useContext(MyContext);
  const {theUseremail} = rootState;
  const [seconds, setSeconds] = useState(60);

  useEffect( () => {
    if(theUseremail === 'confirmemail'){
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        isLoggedInemailempty();
        //setSeconds('BOOOOM!');
      }
     
    }
 }, [seconds,theUseremail]);


  return (<>   <ToastContainer />

    <Router basename="/">
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
        
         <Route exact path="/" component={Home}/>
         <Route exact path="/Login" component={Login}/>
         <Route exact path="/Register" component={Register}/>
         <Route exact path="/ForgetPassword" component={ForgetPassword}/>
         <Route exact path="/ChangePassword/:passkey" component={ChangePassword}/>
         <Route exact path="/MyAccount" component={MyAccount}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/MyAccountReaders" component={MyAccountReaders}/>
         <Route exact path="/MyAccountAuthors" component={MyAccountAuthors}/>
         <Route exact path="/Books/:Addpreview/:booktitle" component={Books}/>
         <Route exact path="/AuthorPage/:Addpreview" component={AuthorPage}/>
         <Route exact path="/Platinum" component={Platinum}/>
         <Route exact path="/Gold" component={Gold}/>
         <Route exact path="/Silver" component={Silver}/>
         <Route exact path="/Featured" component={Featured}/>
         <Route exact path="/Free" component={Free}/>
         <Route exact path="/Categeorylist/:categorytitle" component={Categeorylist}/>
         <Route exact path="/Events" component={Events}/>
         <Route exact path="/BookTrailers" component={BookTrailers}/>
         <Route exact path="/Articles" component={Articles}/>
         <Route exact path="/Poems" component={Poems}/>
         <Route exact path="/VideoPreview/:Addpreview/:booktitle" component={VideoPreview}/>
         <Route exact path="/PoemsPreview/:Addpreview/:booktitle" component={PoemsPreview}/>
         <Route exact path="/ArticlesPreview/:Addpreview/:booktitle" component={ArticlesPreview}/>
         <Route exact path="/EventsPreviews/:Addpreview/:booktitle" component={EventsPreviews}/>
         <Route exact path="/Authors" component={Authors}/>
         <Route exact path="/Notificationall" component={Notificationall}/>
         <Route exact path="/ConfirmEmail/:Addpreview" component={ConfirmEmail}/>
         <Route exact path="/FeaturedBookcolors/:Addpreview/:booktitle" component={FeaturedBookcolors}/>
         <Route exact path="/FeaturedPaymentStepOne/:Addpreview/:Authorsids" component={FeaturedPaymentStepOne}/>
         <Route exact path="/FeaturedPaymentSteptwo/:Addpreview/:Authorsids" component={FeaturedPaymentSteptwo}/>
         <Route exact path="/PaypalSuccess/:Addpreview" component={PaypalSuccess}/>
         <Route exact path="/PaypalCancel" component={PaypalCancel}/>
         <Route exact path="/StripeSuccess/:Addpreview" component={StripeSuccess}/>
         <Route exact path="/CategeorylistRecommended" component={CategeorylistRecommended}/>
         <Route exact path="/CategeorylistLatest" component={CategeorylistLatest}/>
         <Route exact path="/AdminFreeBooks" component={AdminFreeBooks}/>
         <Route exact path="/AdminPaidBooks" component={AdminPaidBooks}/>
         <Route exact path="/ContactBookois" component={ContactBookois}/>
         <Route exact path="/CategeorylistBestsellers" component={CategeorylistBestsellers}/>
         <Route exact path="/AdminVideo" component={AdminVideo}/>
         <Route exact path="/AdminArticle" component={AdminArticle}/>
         <Route exact path="/AdminPoem" component={AdminPoem}/>
         <Route exact path="/EventsOldest" component={EventsOldest}/>
         <Route exact path="/EventsNewest" component={EventsNewest}/>
         <Route exact path="/EventsRegisterdlist/:Addpreview" component={EventsRegisterdlist}/>
         <Route exact path="/EventsViewList/:Addpreview" component={EventsViewList}/>
         <Route exact path="/AdminEvent" component={AdminEvent}/>
         <Route exact path="/AdminReport" component={AdminReport}/>
         <Route exact path="/PressRelease" component={PressRelease}/>
         <Route exact path="/PressReleaseEdit/:Addpreview" component={PressReleaseEdit}/>
         <Route exact path="/Pressauthorpreviews/:Addpreview" component={Pressauthorpreviews}/>
         <Route exact path="/PressReleaseall" component={PressReleaseall}/>
         <Route exact path="/PressReleasePreview/:Addpreview/:booktitle" component={PressReleasePreview}/>
         <Route exact path="/AuthorUpdates" component={AuthorUpdates}/>
         <Route exact path="/AuthorUpdatespreviews/:Addpreview" component={AuthorUpdatespreviews}/>
         <Route exact path="/AuthorsupdateEdit/:Addpreview" component={AuthorsupdateEdit}/>
         <Route exact path="/AuthorUpdatesall" component={AuthorUpdatesall}/>
         <Route exact path="/AuthorUpdatesallPreviews/:Addpreview" component={AuthorUpdatesallPreviews}/>
         <Route exact path="/SearchAllBooks/:booktitle" component={SearchAllBooks}/>
         <Route exact path="/SearchAllAuthors/:booktitle" component={SearchAllAuthors}/>
         <Route exact path="/SearchAllEvents/:booktitle" component={SearchAllEvents}/>
         <Route exact path="/SearchAllArticles/:booktitle" component={SearchAllArticles}/>
         <Route exact path="/SearchAllPoems/:booktitle" component={SearchAllPoems}/>
         <Route exact path="/SearchAllVideos/:booktitle" component={SearchAllVideos}/>
         <Route exact path="/SearchAllPressRelease/:booktitle" component={SearchAllPressRelease}/>
         <Route exact path="/AdminPressRelease" component={AdminPressRelease}/>
         <Route exact path="/AdminPressReleasePreview/:Addpreview" component={AdminPressReleasePreview}/>
         <Route exact path="/AdminPressReleaseEdit/:Addpreview" component={AdminPressReleaseEdit}/>
         <Route exact path="/AdminReview" component={AdminReview}/>
         <Route exact path="/FbStatus/:Addpreview" component={FbStatus}/>
         <Route exact path="/About" component={About}/>
       
     
        </Switch>
      </Suspense>
    </Router>
    </>
  );
}
export default Dashboard;
