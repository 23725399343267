
//export const WEB_URL_UPLOAD = "http://localhost/bookbubworld/uploads/";
//export const WEB_URL = "http://192.168.0.128:8080/";
export const GOOGLE_CLIENTID = "428908088552-ol29n9ncigoip2duing7na2e5d1s0r7l.apps.googleusercontent.com";

export const FACEBOOK_APPID = "859363811311050";
//export const FACEBOOK_APPID = "744037816232830";

export const API_KEY_STRIPE = "pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8";


export const CAPTCHA_SITE_KEY = "6Lci8VwaAAAAAK8Cv5NrosBu_gfGsKKCmNTmXLIt";



export const WEB_URL_UPLOAD = "https://bookois.com/uploads/";
export const WEB_URL = "https://bookois.com/";

//status --- 0 --- follow






