
import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
  

    state = {
        isAuth:false,
        theUserid:null,
        theUsername:null,
        theUsertype:null,
        theUsergoogle:null,
        theUseremail:null
    }
    componentDidMount(){
        this.isLoggedIn();
    }
    
        isLoggedIn = async () => {
            let bubworldTokenid = JSON.parse(localStorage.getItem('bookworldTokenid'));
            let bubworldTokenname = JSON.parse(localStorage.getItem('bookworldTokenname'));
            let bubworldTokentype = JSON.parse(localStorage.getItem('bookworldTokentype'));
            let bubworldTokengoogle = JSON.parse(localStorage.getItem('bookworldTokengoogle'));
            let bubworldTokenemail = JSON.parse(localStorage.getItem('bookworldTokenemail'));
            if (localStorage.getItem('bookworldTokenid')) {
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUserid:bubworldTokenid,
                    theUsername:bubworldTokenname,
                    theUsertype:bubworldTokentype,
                    theUsergoogle:bubworldTokengoogle,
                    theUseremail:bubworldTokenemail,
                });
                //console.log(this.state.isAuth);
            }
        }
        isLoggedIntype = async () => {
            let bubworldTokentype = JSON.parse(localStorage.getItem('bookworldTokentype'));
            if (localStorage.getItem('bookworldTokentype')) {
                this.setState({
                    ...this.state,
                    theUsertype:bubworldTokentype,
                });
            }
        } 
       
        isLoggedInemailempty = async () => {
            localStorage.removeItem('bookworldTokenemail');
                this.setState({
                    ...this.state,
                    theUseremail:null,
                });
        }
        logoutUser = () => {
            localStorage.removeItem('bookworldTokenid');
            localStorage.removeItem('bookworldTokenname');
            localStorage.removeItem('bookworldTokentype');
            localStorage.removeItem('bookworldTokengoogle');
            localStorage.removeItem('bookworldTokenemail');
            this.setState({
                ...this.state,
                isAuth:false,
                theUserid:null,
                theUsername:null,
                theUsertype:null,
                theUsergoogle:null,
                theUseremail:null
            })
            
            window.location.href = '/Login';
        }
        menuslistall = async () => {
            const menuslistallqqq = await Axios.get('menuslistallsss');
            return menuslistallqqq.data;
        }
        // footermenuslistall = async () => {
        //     const footermenuslistallqqq = await Axios.get('footermenuslistallsss');
        //     return footermenuslistallqqq.data;
        // }
        RegisterUser = async (user,recaptcha_response,ispennameActive) => {
            const registerqqq = await Axios.post('Register',{
                user_name:user.name,
                penname:user.penname,
                type:user.type,
                user_email:user.email,
                password:user.password,
                subscribe:user.subscribe, 
                descothers:user.descothers, 
                Active:ispennameActive, 
                recaptcha_response:recaptcha_response,
            });
            return registerqqq.data;
        }
        RegisterUsergoogle = async (user) => {
            const RegisterUsergoogleqqq = await Axios.post('RegisterUsergooglesss',{
                user_name:user.name,
                user_email:user.email,
                password:user.googleId,
                imageUrl:user.imageUrl,
            });
            return RegisterUsergoogleqqq.data;
        }
        RegisterUserfacebook = async (user) => {
            const RegisterUserfacebookqqq = await Axios.post('RegisterUserfacebooksss',{
                user_name:user.name,
                user_email:user.email,
                password:user.id,
                imageUrl:user.picture.data.url,
            });
            return RegisterUserfacebookqqq.data;
        }
        loginUser = async (user) => {
            const login = await Axios.post('userlogin',{
                email:user.email,
                password:user.password
            });
            return login.data;
        }
        loginUsergoogle = async (user) => {
            const loginUsergoogleqqq = await Axios.post('loginUsergooglesss',{
                email:user.email,
                password:user.googleId
            });
            return loginUsergoogleqqq.data;
        }
        RegisterUseraccounts = async (user,userids) => {
            const RegisterUseraccountsqqq = await Axios.post('RegisterUseraccountssss',{
                type:user.type,
                subscribe:user.subscribe, 
                descothers:user.descothers, 
                userids:userids, 
            });
            return RegisterUseraccountsqqq.data;
        }
        loginUserfacebook = async (user) => {
            const loginUserfacebookqqq = await Axios.post('loginUserfacebooksss',{
                email:user.email,
                password:user.id,
                signedRequest:user.signedRequest,
            });
            return loginUserfacebookqqq.data;
        }
        userlistall = async (ids) => {
            const userlistallqqq = await Axios.post('userlistallsss',{
                ids:ids,
            });
            return userlistallqqq.data;
        }
        userprofileupdateall = async (book,userids,fileempty) => {
            const formData = new FormData();
            formData.append('name',book.name);
            formData.append('penname',book.penname);
            formData.append('type',book.type);
            formData.append('descothers',book.descothers);
            formData.append('avatar',book.file);
            formData.append('desc',book.desc);
            formData.append('facebook',book.facebook);
            formData.append('twitter',book.twitter);
            formData.append('linkedin',book.linkedin);
            formData.append('userids',userids);
            formData.append('fileempty',fileempty);
            const userprofileupdateallqqq = await Axios.post('userprofileupdateallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return userprofileupdateallqqq.data;
        }
        pricelistall = async () => {
            const pricelistallqqq = await Axios.get('pricelistallsss');
            return pricelistallqqq.data;
        }
        buynowall = async (ids,storageid) => {
            const buynowallqqq = await Axios.post('buynowallsss',{
                priceids:ids,
                storageid:storageid,
            });
            return buynowallqqq.data;
        }
        pricelistallorder = async (ids) => {
            const pricelistallorderqqq = await Axios.post('pricelistallordersss',{
                ids:ids,
            });
            return pricelistallorderqqq.data;
        }
        memberstripe = async (token,memberid,plan,price) => {
            const memberstripewww = await Axios.post('memberstripesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
            });
            return memberstripewww.data;
        } 
        stripeorderall = async (ids) => {
            const stripeorderallqqq = await Axios.post('stripeorderallsss',{
                ids:ids,
            });
            return stripeorderallqqq.data;
        } 
        paypalcalstsqqq = async (ids) => {
            const paypalcalstsqqqwww = await Axios.post('paypalcalstsqqqsss',{
                ids:ids,
            });
            return paypalcalstsqqqwww.data;
        } 
        paypalorderall = async (ids) => {
            const paypalorderallqqq = await Axios.post('paypalorderallsss',{
                ids:ids,
            });
            return paypalorderallqqq.data;
        } 
        paypalcancelall = async (ids) => {
            const paypalcancelallqqq = await Axios.post('paypalcancelallsss',{
                ids:ids,
            });
            return paypalcancelallqqq.data;
        } 
        buynowallupgrades = async (ids,storageid,price) => {
            const buynowallupgradesqqq = await Axios.post('buynowallupgradessss',{
                priceids:ids,
                storageid:storageid,
                price:price,
            });
            return buynowallupgradesqqq.data;
        } 
        memberstripeupgrade = async (token,memberid,plan,price) => {
            const memberstripeupgradeqqq = await Axios.post('memberstripeupgradesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
            });
            return memberstripeupgradeqqq.data;
        } 
        pricelistallsliver = async () => {
            const pricelistallsliverqqq = await Axios.get('pricelistallsliversss');
            return pricelistallsliverqqq.data;
        }
        newbookadd = async (book,desc,userids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('price',book.price);
            formData.append('purchase',book.purchase);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('userids',userids);
            formData.append('layouts',book.layouts);
            formData.append('amazon',book.amazon);
            formData.append('barnes',book.barnes);
            formData.append('apple',book.apple);
            formData.append('google',book.google);
            formData.append('kobo',book.kobo);
            const booksaddsssqqq = await Axios.post('bookaddsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return booksaddsssqqq.data;
        }
        pricelistallgold = async () => {
            const pricelistallgoldqqq = await Axios.get('pricelistallgoldsss');
            return pricelistallgoldqqq.data;
        }
        pricelistallplatinum = async () => {
            const pricelistallplatinumqqq = await Axios.get('pricelistallplatinumsss');
            return pricelistallplatinumqqq.data;
        }
        newbookaddfree = async (book,desc,userids,pdf,pdfempty,sdate,edate) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('purchase',book.purchase);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('permalinks',book.permalinks);
            formData.append('userids',userids);
            formData.append('avatarfile',pdf);
            formData.append('pdfempty',pdfempty);
            formData.append('sdate',sdate);
            if(book.permalinks != 'yes'){ 
                formData.append('edate',edate);
            }else{
                formData.append('edate','2077-12-30');
            }
            const newbookaddfreeqqq = await Axios.post('newbookaddfreesss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newbookaddfreeqqq.data;
        }
        newarticlesadd = async (articles,desc,memberid,fileempty) => {
            const formData = new FormData();
            formData.append('title',articles.title);
            formData.append('avatar',articles.file);
            formData.append('desc',desc);
            formData.append('memberid',memberid);
            formData.append('fileempty',fileempty);
            const newarticlesaddqqq = await Axios.post('newarticlesaddsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newarticlesaddqqq.data;
        }
        booklistall = async (memberid) => {
            const booklistallqqq = await Axios.post('booklistallsss',{
                memberid:memberid,
            });
            return booklistallqqq.data;
        }
        booklistallviews = async (memberid) => {
            const booklistallsssviewsqqq = await Axios.post('booklistallsssviews',{
                memberid:memberid,
            });
            return booklistallsssviewsqqq.data;
        }
        booklistallfree = async (memberid) => {
            const booklistallfreeqqq = await Axios.post('booklistallfreesss',{
                memberid:memberid,
            });
            return booklistallfreeqqq.data;
        }
        articleslistall = async (memberid) => {
            const articleslistallqqq = await Axios.post('articleslistallsss',{
                memberid:memberid,
            });
            return articleslistallqqq.data;
        }
        booklistpreviewall = async (bookids) => {
            const booklistpreviewallqqq = await Axios.post('booklistpreviewallsss',{
                bookids:bookids,
            });
            return booklistpreviewallqqq.data;
        }
        newbookaddfreeupdate = async (book,desc,pdf,pdfempty,sdate,edate,fileempty,bookids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('purchase',book.purchase);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('permalinks',book.permalinks);
            formData.append('avatarfile',pdf);
            formData.append('pdfempty',pdfempty);
            formData.append('sdate',sdate);
            formData.append('fileempty',fileempty);
            formData.append('bookids',bookids);
            if(book.permalinks != 'yes'){ 
                formData.append('edate',edate);
            }else{
                formData.append('edate','2077-12-30');
            }
            const newbookaddfreeupdateqqq = await Axios.post('newbookaddfreeupdatesss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newbookaddfreeupdateqqq.data;
        }
        newbookaddupdate = async (book,desc,fileempty,bookids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('price',book.price);
            formData.append('purchase',book.purchase);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('layouts',book.layouts);
            formData.append('amazon',book.amazon);
            formData.append('barnes',book.barnes);
            formData.append('apple',book.apple);
            formData.append('google',book.google);
            formData.append('kobo',book.kobo);
            formData.append('fileempty',fileempty);
            formData.append('bookids',bookids);
            const newbookaddupdateqqq = await Axios.post('newbookaddupdatesss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newbookaddupdateqqq.data;
        }
        articlelistpreviewall = async (ids) => {
            const articlelistpreviewallqqq = await Axios.post('articlelistpreviewallsss',{
                ids:ids,
            });
            return articlelistpreviewallqqq.data;
        }
        newarticlesaddupdate = async (articles,desc,ids,fileempty) => {
            const formData = new FormData();
            formData.append('title',articles.title);
            formData.append('avatar',articles.file);
            formData.append('desc',desc);
            formData.append('ids',ids);
            formData.append('fileempty',fileempty);
            const newarticlesaddupdateqqq = await Axios.post('newarticlesaddupdatesss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newarticlesaddupdateqqq.data;
        }
        deletearticleslist = async (ids) => {
            const deletearticleslistqqq = await Axios.post('deletearticleslistsss',{
                ids:ids,
            });
            return deletearticleslistqqq.data;
        }
      
        EventsAddall = async (events,desc,ids,sdate,edate,stream,strdesc) => {
            const formData = new FormData();
            formData.append('title',events.title);
            formData.append('organizer',events.organizer);
            formData.append('locations',events.location);
            formData.append('venue',events.venue);
            formData.append('avatar',events.file);
            formData.append('starttime',events.starttime);
            formData.append('endtime',events.endtime);
            formData.append('onlineurl',events.onlineurl);
            formData.append('streamtitle',events.streamtitle);
            formData.append('streamurl',events.streamurl);
            formData.append('tickets',events.tickets);
            formData.append('ticketname',events.ticketname);
            formData.append('ticketquantity',events.ticketquantity);
            formData.append('ticketminimum',events.ticketminimum);
            formData.append('ticketmaximum',events.ticketmaximum);
            formData.append('ticketdesc',events.ticketdesc);
            formData.append('ticketmsg',events.ticketmsg);
            formData.append('category',events.category);
            formData.append('eventslist',events.eventslist);
            formData.append('venueurl',events.venueurl);
            formData.append('announcedurl',events.announcedurl);
            formData.append('timezoneselc',events.timezoneselc);
            formData.append('desc',desc);
            formData.append('ids',ids);
            formData.append('sdate',sdate);
            formData.append('edate',edate);
            formData.append('stream',stream);
            formData.append('strdesc',strdesc);
            const EventsAddallqqq = await Axios.post('EventsAddallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return EventsAddallqqq.data;
        }
        eventlistall = async (ids) => {
            const eventlistallqqq = await Axios.post('eventlistallsss',{
                ids:ids,
            });
            return eventlistallqqq.data;
        }
        eventslistpreviewall = async (ids) => {
            const eventslistpreviewallqqq = await Axios.post('eventslistpreviewallsss',{
                ids:ids,
            });
            return eventslistpreviewallqqq.data;
        }
        deleteeventslist = async (ids) => {
            const deleteeventslistqqq = await Axios.post('deleteeventslistsss',{
                ids:ids,
            });
            return deleteeventslistqqq.data;
        }
        Eventsupdatesall = async (events,desc,ids,sdate,edate,stream,strdesc,fileempty) => {
            const formData = new FormData();
            formData.append('title',events.title);
            formData.append('organizer',events.organizer);
            formData.append('locations',events.location);
            formData.append('venue',events.venue);
            formData.append('avatar',events.file);
            formData.append('starttime',events.starttime);
            formData.append('endtime',events.endtime);
            formData.append('onlineurl',events.onlineurl);
            formData.append('streamtitle',events.streamtitle);
            formData.append('streamurl',events.streamurl);
            formData.append('tickets',events.tickets);
            formData.append('ticketname',events.ticketname);
            formData.append('ticketquantity',events.ticketquantity);
            formData.append('ticketminimum',events.ticketminimum);
            formData.append('ticketmaximum',events.ticketmaximum);
            formData.append('ticketdesc',events.ticketdesc);
            formData.append('ticketmsg',events.ticketmsg);
            formData.append('category',events.category);
            formData.append('eventslist',events.eventslist);
            formData.append('venueurl',events.venueurl);
            formData.append('announcedurl',events.announcedurl);
            formData.append('timezoneselc',events.timezoneselc);
            formData.append('desc',desc);
            formData.append('ids',ids);
            formData.append('sdate',sdate);
            formData.append('edate',edate);
            formData.append('stream',stream);
            formData.append('strdesc',strdesc);
            formData.append('fileempty',fileempty);
            const Eventsupdatesallqqq = await Axios.post('Eventsupdatesallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return Eventsupdatesallqqq.data;
        }
        newvideoadd = async (videos,ids) => {
            const newvideoaddqqq = await Axios.post('newvideoaddsss',{
                title:videos.title,
                url:videos.url,
                desc:videos.desc,
                ids:ids,
            });
            return newvideoaddqqq.data;
        }
        addauthorupdatesall = async (authors,ids) => {
            const addauthorupdatesallqqq = await Axios.post('addauthorupdatesallsss',{
                desc:authors.desc,
                ids:ids,
            });
            return addauthorupdatesallqqq.data;
        }
        videolistall = async (ids) => {
            const videolistallqqq = await Axios.post('videolistallsss',{
                ids:ids,
            });
            return videolistallqqq.data;
        }
        Aulistall = async (ids) => {
            const Aulistallqqq = await Axios.post('Aulistallsss',{
                ids:ids,
            });
            return Aulistallqqq.data;
        }
        videolistpreviewall = async (ids) => {
            const videolistpreviewallqqq = await Axios.post('videolistpreviewallsss',{
                ids:ids,
            });
            return videolistpreviewallqqq.data;
        }
        deletevideolist = async (ids,userids) => {
            const deletevideolistqqq = await Axios.post('deletevideolistsss',{
                ids:ids,
                userids:userids,
            });
            return deletevideolistqqq.data;
        }
        AUlistpreviewall = async (ids) => {
            const AUlistpreviewallqqq = await Axios.post('AUlistpreviewallsss',{
                ids:ids,
            });
            return AUlistpreviewallqqq.data;
        }
        deleteAUlist = async (ids) => {
            const deleteAUlistqqq = await Axios.post('deleteAUlistsss',{
                ids:ids,
            });
            return deleteAUlistqqq.data;
        }
        newvideoedit = async (videos,ids) => {
            const newvideoeditqqq = await Axios.post('newvideoeditsss',{
                title:videos.title,
                url:videos.url,
                desc:videos.desc,
                ids:ids,
            });
            return newvideoeditqqq.data;
        } 
        editauthorupdatesall = async (authors,ids) => {
            const editauthorupdatesallqqq = await Axios.post('editauthorupdatesallsss',{
                desc:authors.desc,
                ids:ids,
            });
            return editauthorupdatesallqqq.data;
        }
        memberslistall = async () => {
            const memberslistallqqq = await Axios.get('memberslistallsss');
            return memberslistallqqq.data;
        }
        joinsfollowlist = async (loginid,authorid) => {
            const joinsfollowlistqqq = await Axios.post('joinsfollowlistsss',{
                loginid:loginid,
                authorid:authorid,
            });
            return joinsfollowlistqqq.data;
        }
        followlist = async (userloginids,authorids) => {
            const followlistqqq = await Axios.post('followlistsss',{
                userloginids:userloginids,
                authorids:authorids,
            });
            return followlistqqq.data;
        }
        Platinumall = async () => {
            const Platinumallqqq = await Axios.get('Platinumallsss');
            return Platinumallqqq.data;
        }
        Goldall = async () => {
            const Goldallqqq = await Axios.get('Goldallsss');
            return Goldallqqq.data;
        }
        Silverall = async () => {
            const Silverallqqq = await Axios.get('Silverallsss');
            return Silverallqqq.data;
        }
        Featuredall = async () => {
            const Featuredallsss = await Axios.get('Featuredallqqq');
            return Featuredallsss.data;
        }
        unfollowlist = async (loginid,authorid) => {
            const unfollowlistqqq = await Axios.post('unfollowlistsss',{
                loginid:loginid,
                authorid:authorid,
            });
            return unfollowlistqqq.data;
        }
        Platinumlist  = async (pageNumber) => {
            const Platinumlistqqq = await Axios.get(`Platinumlistsss/${pageNumber}`);
            return Platinumlistqqq.data;
        }
        categorylistpreviewall  = async (pageNumber,pagetitle) => {
            const categorylistpreviewallqqq = await Axios.get(`categorylistpreviewallsss/${pageNumber}/${pagetitle}`);
            return categorylistpreviewallqqq.data;
        }
        Featuredlist  = async (pageNumber) => {
            const Featuredlistqqq = await Axios.get(`Featuredlistsss/${pageNumber}`);
            return Featuredlistqqq.data;
        }
        Silverlist  = async (pageNumber) => {
            const Silverlistqqq = await Axios.get(`Silverlistsss/${pageNumber}`);
            return Silverlistqqq.data;
        }
        Goldlist  = async (pageNumber) => {
            const Goldlistqqq = await Axios.get(`Goldlistsss/${pageNumber}`);
            return Goldlistqqq.data;
        }
        Freelist  = async (pageNumber) => {
            const Freelistqqq = await Axios.get(`Freelistsss/${pageNumber}`);
            return Freelistqqq.data;
        }
        Eventslist  = async (pageNumber) => {
            const Eventslistqqq = await Axios.get(`Eventslistsss/${pageNumber}`);
            return Eventslistqqq.data;
        }
        Trailerslist  = async (pageNumber) => {
            const Trailerslistqqq = await Axios.get(`Trailerslistsss/${pageNumber}`);
            return Trailerslistqqq.data;
        }
        Articleslist  = async (pageNumber) => {
            const Articleslistqqq = await Axios.get(`Articleslistsss/${pageNumber}`);
            return Articleslistqqq.data;
        }
        Poemslist  = async (pageNumber) => {
            const Poemslistqqq = await Axios.get(`Poemslistsss/${pageNumber}`);
            return Poemslistqqq.data;
        }
        featuredauthall  = async (pageNumber) => {
            const featuredauthallqqq = await Axios.get(`featuredauthallsss/${pageNumber}`);
            return featuredauthallqqq.data;
        } 
        Featuredlistrandssall  = async (pageNumber) => {
            const Featuredlistrandssallqqq = await Axios.get(`Featuredlistrandssallsss/${pageNumber}`);
            return Featuredlistrandssallqqq.data;
        }
        authorsssFeaturedall  = async (authorsids) => {
            const authorsssFeaturedallqqq = await Axios.post('authorsssFeaturedallsss',{
                authorsids:authorsids,
            });
            return authorsssFeaturedallqqq.data;
        }
        allfeaturedauthall  = async (pageNumber) => {
            const allfeaturedauthallqqq = await Axios.get(`allfeaturedauthallsss/${pageNumber}`);
            return allfeaturedauthallqqq.data;
        }
        ForgetMailpassword = async (user) => {
            const loginForget = await Axios.post('forgetpasswordsss',{
                email:user.useremail,
            });
            return loginForget.data;
        }
        ChangePasswordall = async (user,passkey) => {
            const ChangePasswordallsss = await Axios.post('ChangePasswordallqqq',{
                newpassword:user.password,
                passkeyids:passkey,
            });
            return ChangePasswordallsss.data;
        }
        confirmemailpreviewssall = async (ids) => {
            const confirmemailpreviewssallqqq = await Axios.post('confirmemailpreviewssallsss',{
                ids:ids,
            });
            return confirmemailpreviewssallqqq.data;
        }
        follownotifactioncount = async (ids) => {
            const follownotifactioncountqqq = await Axios.post('follownotifactioncountsss',{
                loginids:ids,
            });
            return follownotifactioncountqqq.data;
        }
        notifactionupdatelist = async (ids) => {
            const notifactionupdatelistqqq = await Axios.post('notifactionupdatelistsss',{
                loginids:ids,
            });
            return notifactionupdatelistqqq.data;
        }
        changelistall = async (background,title,desc,butbg,buttext,autnames,tag,booksids) => {
            const changelistallqqq = await Axios.post('changelistallsss',{
                background:background,
                title:title,
                desc:desc,
                butbg:butbg,
                buttext:buttext,
                autnames:autnames,
                tag:tag,
                booksids:booksids,
            });
            return changelistallqqq.data;
        }
        featuredlistpriceall = async (ids) => {
            const featuredlistpriceallqqq = await Axios.post('featuredlistpriceallsss',{
                ids:ids,
            });
            return featuredlistpriceallqqq.data;
        }
        changepriceaddall = async (pricesss,booksids) => {
            const changepriceaddallqqq = await Axios.post('changepriceaddallsss',{
                pricesss:pricesss,
                booksids:booksids,
            });
            return changepriceaddallqqq.data;
        }
        paypalorderfeaturedall = async (paymentids) => {
            const paypalorderfeaturedallqqq = await Axios.post('paypalorderfeaturedallsss',{
                paymentids:paymentids,
            });
            return paypalorderfeaturedallqqq.data;
        }
        memberstripefeatured = async (token,memberid,plan,price,booksids) => {
            const memberstripefeaturedqqq = await Axios.post('memberstripefeaturedsss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
                booksids:booksids,
            });
            return memberstripefeaturedqqq.data;
        } 
        stripeorderallfeatured = async (paymentids) => {
            const stripeorderallfeaturedqqq = await Axios.post('stripeorderallfeaturedsss',{
                paymentids:paymentids,
            });
            return stripeorderallfeaturedqqq.data;
        }
        Authorslimitsssall = async () => {
            const Authorslimitsssallqqq = await Axios.get(`Authorslimitsssallsss`);
            return Authorslimitsssallqqq.data;
        }
        plassslistrandssall = async (pageNumber) => {
            const plassslistrandssallqqq = await Axios.get(`plassslistrandssallsss/${pageNumber}`);
            return plassslistrandssallqqq.data;
        }
        PoemAddbooksall = async (userinfo,desc,userids) => {
            const PoemAddbooksallqqq = await Axios.post('PoemAddbooksallsss',{
                title:userinfo.title,
                desc:desc,
                userids:userids,
            });
            return PoemAddbooksallqqq.data;
        }
        poemslistall = async (userids) => {
            const poemslistallqqq = await Axios.post('poemslistallsss',{
                userids:userids,
            });
            return poemslistallqqq.data;
        }
        poemlistpreviewall = async (ids) => {
            const poemlistpreviewallqqq = await Axios.post('poemlistpreviewallsss',{
                ids:ids,
            });
            return poemlistpreviewallqqq.data;
        }
        deletepoemlist = async (ids) => {
            const deletepoemlistqqq = await Axios.post('deletepoemlistsss',{
                ids:ids,
            });
            return deletepoemlistqqq.data;
        }
        newPoemaddupdate = async (userinfo,desc,ids) => {
            const newPoemaddupdateqqq = await Axios.post('newPoemaddupdatesss',{
                title:userinfo.title,
                desc:desc,
                ids:ids,
            });
            return newPoemaddupdateqqq.data;
        }
        poemslistpreviewall = async (ids) => {
            const poemslistpreviewallqqq = await Axios.post('poemslistpreviewallsss',{
                ids:ids,
            });
            return poemslistpreviewallqqq.data;
        }
        messagenotifactionlist = async (pageNumber,storageid) => {
            const messagenotifactionlistqqq = await Axios.get(`messagenotifactionlistsss/${pageNumber}/${storageid}`);
                return messagenotifactionlistqqq.data;
        }
        Wishlistall = async (booksids,loginids) => {
            const Wishlistallqqq = await Axios.post('Wishlistallsss',{
                booksids:booksids,
                loginids:loginids,
            });
            return Wishlistallqqq.data;
        }
        Wishlistallviews = async (readersids) => {
            const Wishlistallviewsqqq = await Axios.post('Wishlistallviewsss',{
                readersids:readersids,
            });
            return Wishlistallviewsqqq.data;
        }
        wishlistsqlsall = async (booksids,readersids) => {
            const wishlistsqlsallqqq = await Axios.post('wishlistsqlsallsss',{
                booksids:booksids,
                readersids:readersids,
            });
            return wishlistsqlsallqqq.data;
        }
       
        followinglistall = async (ids) => {
            const followinglistallqqq = await Axios.post('followinglistallsss',{
                ids:ids,
            });
            return followinglistallqqq.data;
        }
        followerslistall = async (ids) => {
            const followerslistallqqq = await Axios.post('followerslistallsss',{
                ids:ids,
            });
            return followerslistallqqq.data;
        }
        paypallistallreceipt = async (ids) => {
            const paypallistallreceiptqqq = await Axios.post('paypallistallreceiptsss',{
                ids:ids,
            });
            return paypallistallreceiptqqq.data;
        }
        stripelistallreceipt = async (ids) => {
            const stripelistallreceiptqqq = await Axios.post('stripelistallreceiptsss',{
                ids:ids,
            });
            return stripelistallreceiptqqq.data;
        }
        AdminFreelist  = async (pageNumber) => {
            const AdminFreelistqqq = await Axios.get(`AdminFreelistsss/${pageNumber}`);
            return AdminFreelistqqq.data;
        }
        Approvedbookslist  = async (ids) => {
            const Approvedbookslistqqq = await Axios.post('Approvedbookslistsss',{
                ids:ids,
            });
            return Approvedbookslistqqq.data;
        }
        deletesbookslist  = async (ids) => {
            const deletesbookslistqqq = await Axios.post('deletesbookslistsss',{
                ids:ids,
            });
            return deletesbookslistqqq.data;
        }
        AdminPaidlist  = async (pageNumber) => {
            const AdminPaidlistqqq = await Axios.get(`AdminPaidlistsss/${pageNumber}`);
            return AdminPaidlistqqq.data;
        }
        Bestswllerslistrandssall  = async (pageNumber) => {
            const Bestswllerslistrandssallqqq = await Axios.get(`Bestswllerslistrandssallsss/${pageNumber}`);
            return Bestswllerslistrandssallqqq.data;
        }
        authorssstrasssall  = async (authorsids) => {
            const authorssstrasssallqqq = await Axios.post('authorssstrasssallsss',{
                authorsids:authorsids,
            });
            return authorssstrasssallqqq.data;
        }
        authorsssarticlsall  = async (authorsids) => {
            const authorsssarticlsallqqq = await Axios.post('authorsssarticlsallsss',{
                authorsids:authorsids,
            });
            return authorsssarticlsallqqq.data;
        }
        deletesvideoslist  = async (ids) => {
            const deletesvideoslistqqq = await Axios.post('deletesvideoslistsss',{
                ids:ids,
            });
            return deletesvideoslistqqq.data;
        }
        pinnedlist  = async (booksids,authorids) => {
            const pinnedlistqqq = await Axios.post('pinnedlistsss',{
                booksids:booksids,
                authorids:authorids,
            });
            return pinnedlistqqq.data;
        }
        
        deletesArticlelist  = async (ids) => {
            const deletesArticlelistqqq = await Axios.post('deletesArticlelistsss',{
                ids:ids,
            });
            return deletesArticlelistqqq.data;
        }
        deletespoemslist  = async (ids) => {
            const deletespoemslistqqq = await Axios.post('deletespoemslistsss',{
                ids:ids,
            });
            return deletespoemslistqqq.data;
        }
        eventlistpreviewall  = async (ids) => {
            const eventlistpreviewallqqq = await Axios.post('eventlistpreviewallsss',{
                ids:ids,
            });
            return eventlistpreviewallqqq.data;
        }
        Eventslistoldest  = async (pageNumber) => {
            const Eventslistoldestqqq = await Axios.get(`Eventslistoldestsss/${pageNumber}`);
            return Eventslistoldestqqq.data;
        }
        Eventslistnewest  = async (pageNumber) => {
            const Eventslistnewestqqq = await Axios.get(`Eventslistnewestsss/${pageNumber}`);
            return Eventslistnewestqqq.data;
        }
        infoRegisterEventsall  = async (ids) => {
            const infoRegisterEventsallqqq = await Axios.post('infoRegisterEventsallsss',{
                ids:ids,
            });
            return infoRegisterEventsallqqq.data;
        }
        Eventsinfosssall  = async (userinfo,userids,eventids,title,eventsuserid) => {
            const Eventsinfosssallqqq = await Axios.post('Eventsinfosssallsss',{
                name:userinfo.name,
                email:userinfo.email,
                phone:userinfo.phone,
                ids:userids,
                eventids:eventids,
                title:title,
                eventsuserid:eventsuserid,
            });
            return Eventsinfosssallqqq.data;
        }
        eventcheckregisteredall  = async (eventids,userids) => {
            const eventcheckregisteredallqqq = await Axios.post('eventcheckregisteredallsss',{
                eventids:eventids,
                ids:userids,
            });
            return eventcheckregisteredallqqq.data;
        }
        Eventsinfosssallcancel  = async (userids,eventids) => {
            const Eventsinfosssallcancelqqq = await Axios.post('Eventsinfosssallcancelsss',{
                ids:userids,
                eventids:eventids,
            });
            return Eventsinfosssallcancelqqq.data;
        }
        Eventsinfosssallticket  = async (ticketslist,totaltickets) => {
            console.log(ticketslist);
            let rows = [];
            for (var i = 0; i < totaltickets; i++) {
            rows.push(ticketslist[i]);
            }
                console.log('wwwwwww',rows);
            const Eventsinfosssallticketqqq = await Axios.post('Eventsinfosssallticketsss',rows);
            
            return Eventsinfosssallticketqqq.data;
        }
        Eventsallticketupdates  = async (ticketslist,totaltickets) => {
            console.log(ticketslist);
            let rows = [];
            for (var i = 0; i < totaltickets; i++) {
            rows.push(ticketslist[i]);
            }
                console.log('wwwwwww',rows);
            const Eventsallticketupdatesqqq = await Axios.post('Eventsallticketupdatesss',rows);
            
            return Eventsallticketupdatesqqq.data;
        }
        registeredlistpreviewall  = async (eventsids) => {
            const registeredlistpreviewallqqq = await Axios.post('registeredlistpreviewallsss',{
                eventsids:eventsids,
            });
            return registeredlistpreviewallqqq.data;
        }
        registeredlistallsss  = async (eventsids,useridsss) => {
            const registeredlistallssswww = await Axios.post('registeredlistallsssqqq',{
                eventsids:eventsids,
                useridsss:useridsss,
            });
            return registeredlistallssswww.data;
        }
        authorchecklistall  = async (eventsids) => {
            const authorchecklistallqqq = await Axios.post('authorchecklistallsss',{
                eventsids:eventsids,
            });
            return authorchecklistallqqq.data;
        }
        readerchecklistall  = async (readersids) => {
            const readerchecklistallqqq = await Axios.post('readerchecklistallsss',{
                readersids:readersids,
            });
            return readerchecklistallqqq.data;
        }
        authorslistpreviewall  = async (readersids) => {
            const authorslistpreviewallqqq = await Axios.post('authorslistpreviewallsss',{
                readersids:readersids,
            });
            return authorslistpreviewallqqq.data;
        }
        Featuredpressall  = async (ids) => {
            const Featuredpressallqqq = await Axios.post('Featuredpressallsss',{
                ids:ids,
            });
            return Featuredpressallqqq.data;
        }
        Featuredpressallpreviews  = async (ids) => {
            const Featuredpressallpreviewsqqq = await Axios.post('Featuredpressallpreviewssss',{
                ids:ids,
            });
            return Featuredpressallpreviewsqqq.data;
        }
        deletespressreleaseall  = async (ids) => {
            const deletespressreleaseallqqq = await Axios.post('deletespressreleaseallsss',{
                ids:ids,
            });
            return deletespressreleaseallqqq.data;
        }
        pressapreviewsimg  = async (ids) => {
            const pressapreviewsimgqqq = await Axios.post('pressapreviewsimgsss',{
                ids:ids,
            });
            return pressapreviewsimgqqq.data;
        }
        Presslist  = async (pageNumber) => {
            const Presslistqqq = await Axios.get(`Presslistsss/${pageNumber}`);
            return Presslistqqq.data;
        }
        FeaturedUpdatesall  = async (ids) => {
            const FeaturedUpdatesallqqq = await Axios.post('FeaturedUpdatesallsss',{
                ids:ids,
            });
            return FeaturedUpdatesallqqq.data;
        }
        deletesauthorsupdatesall  = async (ids) => {
            const deletesauthorsupdatesallqqq = await Axios.post('deletesauthorsupdatesallsss',{
                ids:ids,
            });
            return deletesauthorsupdatesallqqq.data;
        }
        Featuredupdateallpreviews  = async (ids) => {
            const Featuredupdateallpreviewsqqq = await Axios.post('Featuredupdateallpreviewsss',{
                ids:ids,
            });
            return Featuredupdateallpreviewsqqq.data;
        }
        updatespreviewsimg  = async (ids) => {
            const updatespreviewsimgqqq = await Axios.post('updatespreviewsimgsss',{
                ids:ids,
            });
            return updatespreviewsimgqqq.data;
        }
        AuthorUpdatesalllist  = async (pageNumber) => {
            const AuthorUpdatesalllistqqq = await Axios.get(`AuthorUpdatesalllistsss/${pageNumber}`);
            return AuthorUpdatesalllistqqq.data;
        }
        ApiBooksAdstopmenusall  = async (searchvalue) => {
            const ApiBooksAdstopmenusallqqq = await Axios.get(`ApiBooksAdstopmenusallsss/${searchvalue}`);
            return ApiBooksAdstopmenusallqqq.data;
        }
        bookslistpreviewall  = async (pageNumber,searchvalue) => {
            const bookslistpreviewallqqq = await Axios.get(`bookslistpreviewallsss/${pageNumber}/${searchvalue}`);
            return bookslistpreviewallqqq.data;
        }
        authorslistsearchall  = async (pageNumber,searchvalue) => {
            const authorslistsearchallqqq = await Axios.get(`authorslistsearchallsss/${pageNumber}/${searchvalue}`);
            return authorslistsearchallqqq.data;
        }
        Eventssearchpreviewall  = async (pageNumber,searchvalue) => {
            const Eventssearchpreviewallqqq = await Axios.get(`Eventssearchpreviewallsss/${pageNumber}/${searchvalue}`);
            return Eventssearchpreviewallqqq.data;
        }
        Articlessearchpreviewall  = async (pageNumber,searchvalue) => {
            const Articlessearchpreviewallqqq = await Axios.get(`Articlessearchpreviewallsss/${pageNumber}/${searchvalue}`);
            return Articlessearchpreviewallqqq.data;
        }
        Poemssearchpreviewall  = async (pageNumber,searchvalue) => {
            const Poemssearchpreviewallqqq = await Axios.get(`Poemssearchpreviewallsss/${pageNumber}/${searchvalue}`);
            return Poemssearchpreviewallqqq.data;
        }
        Videossearchpreviewall  = async (pageNumber,searchvalue) => {
            const Videossearchpreviewallqqq = await Axios.get(`Videossearchpreviewallsss/${pageNumber}/${searchvalue}`);
            return Videossearchpreviewallqqq.data;
        }
        Presssearchpreviewall  = async (pageNumber,searchvalue) => {
            const Presssearchpreviewallqqq = await Axios.get(`Presssearchpreviewallsss/${pageNumber}/${searchvalue}`);
            return Presssearchpreviewallqqq.data;
        }
        ApiAuthorsAdstopmenusall  = async (searchvalue) => {
            const ApiAuthorsAdstopmenusallqqq = await Axios.get(`ApiAuthorsAdstopmenusallsss/${searchvalue}`);
            return ApiAuthorsAdstopmenusallqqq.data;
        }
        reportfreesadminall  = async (ids,userinfo) => {
            const reportfreesadminallqqq = await Axios.post('reportfreesadminallsss',{
                ids:ids,
                free: userinfo.free ,
                BookTitle: userinfo.BookTitle ,
                Author: userinfo.Author ,
                Description: userinfo.Description ,
                RetailerLinks: userinfo.RetailerLinks ,
                ReleaseDate: userinfo.ReleaseDate ,
                IncorrectTags: userinfo.IncorrectTags ,
                CoverImage: userinfo.CoverImage ,
                Other: userinfo.Other ,
                desc: userinfo.desc ,
            });
            return reportfreesadminallqqq.data;
        }
        gpostreportsall  = async (pageNumber) => {
            const gpostreportsallqqq = await Axios.get(`gpostreportsallsss/${pageNumber}`);
            return gpostreportsallqqq.data;
        }
        booksreviewsaddall  = async (gradeIndex,userInfo,hobbies,storageid,bookadsids) => {
            const booksreviewsaddallqqq = await Axios.post('booksreviewsaddallsss',{
                
                gradeIndex: gradeIndex,
                recommend: userInfo.recommend,
                desc: userInfo.desc,
                Wonderful: hobbies.Wonderful,
                Actionpacked: hobbies.Actionpacked,
                Original: hobbies.Original,
                Greatworld: hobbies.Greatworld,
                Tearjerker: hobbies.Tearjerker,
                Unpredictable: hobbies.Unpredictable,
                Scary: hobbies.Scary,
                Funny: hobbies.Funny,
                Inspirational:hobbies.Inspirational,
                Whimsical: hobbies.Whimsical,
                Haunting: hobbies.Haunting,
                Entertaining: hobbies.Entertaining,
                Witty: hobbies.Witty,
                Tragic: hobbies.Tragic,
                Realistic: hobbies.Realistic,
                Informative: hobbies.Informative,
                Steamy: hobbies.Steamy,
                Pageturner: hobbies.Pageturner,
                Romantic: hobbies.Romantic,
                Twisted: hobbies.Twisted,
                Easytoread: hobbies.Easytoread,
                Happily: hobbies.Happily,
                storageid: storageid,
                bookadsids: bookadsids,
            });
            return booksreviewsaddallqqq.data;
        }
        bookreviewpreviewall = async (bookids) => {
            const bookreviewpreviewallqqq = await Axios.post('bookreviewpreviewallsss',{
                bookids:bookids,
            });
            return bookreviewpreviewallqqq.data;
        }
        reviewlistall  = async (pageNumber,bookids) => {
            const reviewlistallqqq = await Axios.get(`reviewlistallsss/${pageNumber}/${bookids}`);
            return reviewlistallqqq.data;
        }
        booksreviewseditall  = async (gradeIndex,userInfo,hobbies,reviewsid) => {
            const booksreviewseditallqqq = await Axios.post('booksreviewseditallsss',{
                
                gradeIndex: gradeIndex,
                recommend: userInfo.recommend,
                desc: userInfo.desc,
                Wonderful: hobbies.Wonderful,
                Actionpacked: hobbies.Actionpacked,
                Original: hobbies.Original,
                Greatworld: hobbies.Greatworld,
                Tearjerker: hobbies.Tearjerker,
                Unpredictable: hobbies.Unpredictable,
                Scary: hobbies.Scary,
                Funny: hobbies.Funny,
                Inspirational:hobbies.Inspirational,
                Whimsical: hobbies.Whimsical,
                Haunting: hobbies.Haunting,
                Entertaining: hobbies.Entertaining,
                Witty: hobbies.Witty,
                Tragic: hobbies.Tragic,
                Realistic: hobbies.Realistic,
                Informative: hobbies.Informative,
                Steamy: hobbies.Steamy,
                Pageturner: hobbies.Pageturner,
                Romantic: hobbies.Romantic,
                Twisted: hobbies.Twisted,
                Easytoread: hobbies.Easytoread,
                Happily: hobbies.Happily,
                reviewsid: reviewsid,
            });
            return booksreviewseditallqqq.data;
        }
        deletesreviewslist = async (reviewsids) => {
            const deletesreviewslistqqq = await Axios.post('deletesreviewslistsss',{
                reviewsids:reviewsids,
            });
            return deletesreviewslistqqq.data;
        }
        reportsaddsssall = async (booksids,reviewsid) => {
            const reportsaddsssallqqq = await Axios.post('reportsaddsssallsss',{
                booksids:booksids,
                reviewsid:reviewsid,
            });
            return reportsaddsssallqqq.data;
        }
        Reviewreportlist  = async (pageNumber) => {
            const Reviewreportlistqqq = await Axios.get(`Reviewreportlistsss/${pageNumber}`);
            return Reviewreportlistqqq.data;
        }
        deletesreportslist = async (reportsid) => {
            const deletesreportslistqqq = await Axios.post('deletesreportslistsss',{
                reportsid:reportsid,
            });
            return deletesreportslistqqq.data;
        }
        admincheckreportall  = async () => {
            const admincheckreportallqqq = await Axios.get(`admincheckreportallsss`);
            return admincheckreportallqqq.data;
        }
        LikesActiveAdd  = async (likefeedid,theUserid) => {
            const LikesActiveAddqqq = await Axios.post(`LikesActiveAddsss`,{
                likefeedid:likefeedid,
                theUserid:theUserid,
            });
            return LikesActiveAddqqq.data;
        }
        latestlikeslist  = async (likefeedid,theUserid) => {
            const latestlikeslistqqq = await Axios.post(`latestlikeslistsss`,{
                likefeedid:likefeedid,
                theUserid:theUserid,
            });
            return latestlikeslistqqq.data;
        }
        LikesActiveupdate  = async (likeid,feedids) => {
            const LikesActiveupdateqqq = await Axios.post(`LikesActiveupdatesss`,{
                likeid:likeid,
                feedids:feedids,
            });
            return LikesActiveupdateqqq.data;
        }
        newcommentadd = async (newfeedcomment, userid,fileempty,postids) => {
            const formData = new FormData();
            formData.append('comment',newfeedcomment.comment);
            formData.append('avatar',newfeedcomment.file);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            formData.append('postids',postids);
            const newcommentaddqqq = await Axios.post('newcommentaddsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newcommentaddqqq.data;
        }
        commentlistall = async (postids) => {
            const commentlistallqqq = await Axios.post('commentlistallsss',{
                postids:postids,
            });
            return commentlistallqqq.data;
        }
        deletescommentlist = async (postids) => {
            const deletescommentlistqqq = await Axios.post('deletescommentlistsss',{
                postids:postids,
            });
            return deletescommentlistqqq.data;
        }
        editfeedscommentall = async (newfeedcomment, userid,fileempty,feedsids) => {
            const formData = new FormData();
            formData.append('comment',newfeedcomment.comment);
            formData.append('avatar',newfeedcomment.file);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            formData.append('feedsids',feedsids);
            const editfeedscommentallqqq = await Axios.post('editfeedscommentallsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editfeedscommentallqqq.data;
        }
        addreplycommentall = async (userinfo,feedid,theUserid) => {
            const addreplycommentallqqq = await Axios.post('addreplycommentallsss',{
                comment:userinfo.replycomment,
                feedid:feedid,
                theUserid:theUserid,
            });
            return addreplycommentallqqq.data;
        }
        commentreplylistall = async (feedid) => {
            const commentreplylistallqqq = await Axios.post('commentreplylistallsss',{
                feedid:feedid,
            });
            return commentreplylistallqqq.data;
        }
        editreplycommentall = async (userinfo,replyid) => {
            const editreplycommentallqqq = await Axios.post('editreplycommentallsss',{
                comment:userinfo.replycomment,
                replyid:replyid,
            });
            return editreplycommentallqqq.data;
        }
        deletesreplycommentlist = async (replyid) => {
            const deletesreplycommentlistqqq = await Axios.post('deletesreplycommentlistsss',{
                replyid:replyid,
            });
            return deletesreplycommentlistqqq.data;
        }
        fbcallback = async (ids) => {
            const fbcallbackqqq = await Axios.post('fbloginstatus',{
                ids:ids,
            });
            return fbcallbackqqq.data;
        }
        

    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            isLoggedIntype:this.isLoggedIntype,
            loginUser:this.loginUser,
            logoutUser:this.logoutUser,
            menuslistall:this.menuslistall,
            //footermenuslistall:this.footermenuslistall,
            RegisterUser:this.RegisterUser,
            RegisterUsergoogle:this.RegisterUsergoogle,
            loginUsergoogle:this.loginUsergoogle,
            RegisterUseraccounts:this.RegisterUseraccounts,
            RegisterUserfacebook:this.RegisterUserfacebook,
            loginUserfacebook:this.loginUserfacebook,
            userlistall:this.userlistall,
            userprofileupdateall:this.userprofileupdateall,
            pricelistall:this.pricelistall,
            buynowall:this.buynowall,
            pricelistallorder:this.pricelistallorder,
            memberstripe:this.memberstripe,
            stripeorderall:this.stripeorderall,
            paypalcalstsqqq:this.paypalcalstsqqq,
            paypalorderall:this.paypalorderall,
            paypalcancelall:this.paypalcancelall,
            buynowallupgrades:this.buynowallupgrades,
            memberstripeupgrade:this.memberstripeupgrade,
            pricelistallsliver:this.pricelistallsliver,
            newbookadd:this.newbookadd,
            pricelistallgold:this.pricelistallgold,
            pricelistallplatinum:this.pricelistallplatinum,
            newbookaddfree:this.newbookaddfree,
            newarticlesadd:this.newarticlesadd,
            booklistall:this.booklistall,
            booklistallviews:this.booklistallviews,
            articleslistall:this.articleslistall,
            booklistpreviewall:this.booklistpreviewall,
            newbookaddfreeupdate:this.newbookaddfreeupdate,
            newbookaddupdate:this.newbookaddupdate,
            articlelistpreviewall:this.articlelistpreviewall,
            newarticlesaddupdate:this.newarticlesaddupdate,
            deletearticleslist:this.deletearticleslist,
            EventsAddall:this.EventsAddall,
            eventlistall:this.eventlistall,
            eventslistpreviewall:this.eventslistpreviewall,
            deleteeventslist:this.deleteeventslist,
            Eventsupdatesall:this.Eventsupdatesall,
            newvideoadd:this.newvideoadd,
            addauthorupdatesall:this.addauthorupdatesall,
            videolistall:this.videolistall,
            Aulistall:this.Aulistall,
            videolistpreviewall:this.videolistpreviewall,
            deletevideolist:this.deletevideolist,
            AUlistpreviewall:this.AUlistpreviewall,
            deleteAUlist:this.deleteAUlist,
            newvideoedit:this.newvideoedit,
            editauthorupdatesall:this.editauthorupdatesall,
            memberslistall:this.memberslistall,
            joinsfollowlist:this.joinsfollowlist,
            followlist:this.followlist,
            Platinumall:this.Platinumall,
            Goldall:this.Goldall,
            Silverall:this.Silverall,
            Featuredall:this.Featuredall,
            unfollowlist:this.unfollowlist,
            Platinumlist:this.Platinumlist,
            categorylistpreviewall:this.categorylistpreviewall,
            Featuredlist:this.Featuredlist,
            Silverlist:this.Silverlist,
            Goldlist:this.Goldlist,
            Freelist:this.Freelist,
            Eventslist:this.Eventslist,
            Trailerslist:this.Trailerslist,
            Articleslist:this.Articleslist,
            Poemslist:this.Poemslist,
            featuredauthall:this.featuredauthall,
            authorsssFeaturedall:this.authorsssFeaturedall,
            allfeaturedauthall:this.allfeaturedauthall,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,
            isLoggedInemailempty:this.isLoggedInemailempty,
            confirmemailpreviewssall:this.confirmemailpreviewssall,
            follownotifactioncount:this.follownotifactioncount,
            notifactionupdatelist:this.notifactionupdatelist,
            booklistallfree:this.booklistallfree,
            changelistall:this.changelistall,
            featuredlistpriceall:this.featuredlistpriceall,
            changepriceaddall:this.changepriceaddall,
            paypalorderfeaturedall:this.paypalorderfeaturedall,
            memberstripefeatured:this.memberstripefeatured,
            stripeorderallfeatured:this.stripeorderallfeatured,
            Authorslimitsssall:this.Authorslimitsssall,
            Featuredlistrandssall:this.Featuredlistrandssall,
            plassslistrandssall:this.plassslistrandssall,
            PoemAddbooksall:this.PoemAddbooksall,
            poemslistall:this.poemslistall,
            poemlistpreviewall:this.poemlistpreviewall,
            deletepoemlist:this.deletepoemlist,
            newPoemaddupdate:this.newPoemaddupdate,
            poemslistpreviewall:this.poemslistpreviewall,
            messagenotifactionlist:this.messagenotifactionlist,
            Wishlistall:this.Wishlistall,
            Wishlistallviews:this.Wishlistallviews,
            wishlistsqlsall:this.wishlistsqlsall,
            followinglistall:this.followinglistall,
            followerslistall:this.followerslistall,
            paypallistallreceipt:this.paypallistallreceipt,
            stripelistallreceipt:this.stripelistallreceipt,
            AdminFreelist:this.AdminFreelist,
            Approvedbookslist:this.Approvedbookslist,
            deletesbookslist:this.deletesbookslist,
            AdminPaidlist:this.AdminPaidlist,
            Bestswllerslistrandssall:this.Bestswllerslistrandssall,
            authorssstrasssall:this.authorssstrasssall,
            authorsssarticlsall:this.authorsssarticlsall,
            deletesvideoslist:this.deletesvideoslist,
            pinnedlist:this.pinnedlist,
            deletesArticlelist:this.deletesArticlelist,
            deletespoemslist:this.deletespoemslist,
            eventlistpreviewall:this.eventlistpreviewall,
            Eventslistoldest:this.Eventslistoldest,
            infoRegisterEventsall:this.infoRegisterEventsall,
            Eventsinfosssall:this.Eventsinfosssall,
            eventcheckregisteredall:this.eventcheckregisteredall,
            Eventsinfosssallcancel:this.Eventsinfosssallcancel,
            Eventslistnewest:this.Eventslistnewest,
            Eventsinfosssallticket:this.Eventsinfosssallticket,
            Eventsallticketupdates:this.Eventsallticketupdates,
            registeredlistpreviewall:this.registeredlistpreviewall,
            registeredlistallsss:this.registeredlistallsss,
            authorchecklistall:this.authorchecklistall,
            readerchecklistall:this.readerchecklistall,
            authorslistpreviewall:this.authorslistpreviewall,
            Featuredpressall:this.Featuredpressall,
            Featuredpressallpreviews:this.Featuredpressallpreviews,
            deletespressreleaseall:this.deletespressreleaseall,
            pressapreviewsimg:this.pressapreviewsimg,
            Presslist:this.Presslist,
            FeaturedUpdatesall:this.FeaturedUpdatesall,
            deletesauthorsupdatesall:this.deletesauthorsupdatesall,
            Featuredupdateallpreviews:this.Featuredupdateallpreviews,
            updatespreviewsimg:this.updatespreviewsimg,
            AuthorUpdatesalllist:this.AuthorUpdatesalllist,
            ApiBooksAdstopmenusall:this.ApiBooksAdstopmenusall,
            bookslistpreviewall:this.bookslistpreviewall,
            authorslistsearchall:this.authorslistsearchall,
            Eventssearchpreviewall:this.Eventssearchpreviewall,
            Articlessearchpreviewall:this.Articlessearchpreviewall,
            Poemssearchpreviewall:this.Poemssearchpreviewall,
            Videossearchpreviewall:this.Videossearchpreviewall,
            Presssearchpreviewall:this.Presssearchpreviewall,
            ApiAuthorsAdstopmenusall:this.ApiAuthorsAdstopmenusall,
            reportfreesadminall:this.reportfreesadminall,
            gpostreportsall:this.gpostreportsall,
            booksreviewsaddall:this.booksreviewsaddall,
            bookreviewpreviewall:this.bookreviewpreviewall,
            reviewlistall:this.reviewlistall,
            booksreviewseditall:this.booksreviewseditall,
            deletesreviewslist:this.deletesreviewslist,
            reportsaddsssall:this.reportsaddsssall,
            Reviewreportlist:this.Reviewreportlist,
            deletesreportslist:this.deletesreportslist,
            admincheckreportall:this.admincheckreportall,
            LikesActiveAdd:this.LikesActiveAdd,
            latestlikeslist:this.latestlikeslist,
            LikesActiveupdate:this.LikesActiveupdate,
            newcommentadd:this.newcommentadd,
            commentlistall:this.commentlistall,
            deletescommentlist:this.deletescommentlist,
            editfeedscommentall:this.editfeedscommentall,
            addreplycommentall:this.addreplycommentall,
            commentreplylistall:this.commentreplylistall,
            editreplycommentall:this.editreplycommentall,
            deletesreplycommentlist:this.deletesreplycommentlist,
            fbcallback:this.fbcallback,
            
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;